<template>
    <div class="page p-grid">
        <div class="p-col-12">
            <div class="p-col-12">
                <Breadcrumb :model="items"/>
            </div>
            <div class="p-grid p-p-2 p-mt-4">
                <div class="p-col-3">
                    <div style="border: 1px solid #999;height: 130px">

                    </div>
                </div>
                <div class="p-col-9">
                    <div class="p-mt-2">
                        <div class="p-mt-2">
                            <i style="color: #0069d9;font-weight: bold" class="pi pi-fw pi-pause"></i>
                            <label style="font-size: 18px; font-weight: bold; color: #0069d9" >专家风采</label>
                            <div style="border: 1px solid #999"></div>
                            <div class="p-grid p-col-9 p-mt-4">
                                <div class="p-col-5"></div>
                                <div style="border: 1px solid #999;" class="p-col-5">
                                    <div style="height: 200px">此处是图片</div>
                                </div>
                                <div class="p-col-6"></div>
                                <div class="p-col-3">
                                    <label style="color: #0069d9;font-weight: bold">李云迪</label>
                                    研究院、博士生导师 中国服贸贸易协会专家委员会理事长
                                </div>
                            </div>
                            <div class="p-mt-4 p-p-3">
                                <label style="color: #0069d9;font-weight: bold">李云迪</label>
                                继“十四五规划”提出加快第五代移动通信、工业互联网、大数据中心等建设之后，工业互联网发展再迎重磅利好。1月13日，工信部印发了《工业互联网创新发展行动计划（2021-2023年）》（以下简称《计划》）。《计划》指出，2021-2023年是我国工业互联网的快速成长期，要进一步完善新型基础设施、彰显融合应用成效、提升技术创新能力、健全产业发展生态和增强安全保障能力。
                                继“十四五规划”提出加快第五代移动通信、工业互联网、大数据中心等建设之后，工业互联网发展再迎重磅利好。1月13日，工信部印发了《工业互联网创新发展行动计划（2021-2023年）》（以下简称《计划》）。《计划》指出，2021-2023年是我国工业互联网的快速成长期，要进一步完善新型基础设施、彰显融合应用成效、提升技术创新能力、健全产业发展生态和增强安全保障能力。
                                继“十四五规划”提出加快第五代移动通信、工业互联网、大数据中心等建设之后，工业互联网发展再迎重磅利好。1月13日，工信部印发了《工业互联网创新发展行动计划（2021-2023年）》（以下简称《计划》）。《计划》指出，2021-2023年是我国工业互联网的快速成长期，要进一步完善新型基础设施、彰显融合应用成效、提升技术创新能力、健全产业发展生态和增强安全保障能力。
                                继“十四五规划”提出加快第五代移动通信、工业互联网、大数据中心等建设之后，工业互联网发展再迎重磅利好。1月13日，工信部印发了《工业互联网创新发展行动计划（2021-2023年）》（以下简称《计划》）。《计划》指出，2021-2023年是我国工业互联网的快速成长期，要进一步完善新型基础设施、彰显融合应用成效、提升技术创新能力、健全产业发展生态和增强安全保障能力。
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "",
        data() {
            return {
                items: [
                    {label: '智库管理', to: '/assistExtension'},
                    {label: '协助推广', to: '/assistExtension'},
                    {label: '信息编辑', to: '/informationEditor'},
                    {label: '预览', to: '/preview'},
                ]
            }
        }
    }
</script>

<style scoped>

</style>